import React, { FC } from 'react';
import './faq.scss';

interface FaqProps {}
const Faq: FC<FaqProps> = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
  <div className='faq'>
    <div className='subtitle'>Booking Information</div>
    To submit a request, please read all of the following information, then fill out my booking form (at the bottom of this page) and allow 3-7 days for a response.
    <div className="spacer"></div>

    <div className="subtitle">Deposit</div>
    A <span className='bold'>non-refundable</span> deposit ($50 for letterings/flash, $100 for customs) is required to secure your appointment. This deposit will
     go towards the total cost of your tattoo.
    <div className="spacer"></div>

    <div className="subtitle">Hourly Rates </div>
    My current rate is <span className="bold">$180/hr</span> (for California), with a  <span className="bold">minimum of $200</span> per tattoo.<br/>
    <div className="spacer"></div>
    Depending on the length of the word and the font, letterings (5" or smaller) typically range from $200 - $400.
        
    <div className="spacer"></div>
    <div className="subtitle">Customs & Guest Spots</div>
    All customs and tattoos for guest spots will be priced by-piece (flat rate).<br/>
    A price will be provided once we agree on a final design to account for time spent designing as well as provide a more comfortable pace for both the client and artist.
    <div className="spacer"></div>
    If you have a budget, please include that information in the initial request so I can assess feasability and so it can be taken into account in the design process.
    <div className="spacer"></div>

    <div className="subtitle">Rescheduling</div>
    Things happen - <span className='bold'>one</span> reschedule is allowed if you notify me at least 48 hours before your appointment. If you need to reschedule more than once, you will need to put down a new deposit to secure your new appointment.
    
    <div className="spacer"></div>
    Failure to notify me with sufficient notice will result in your deposit being forfeited and a new deposit will be required to secure a new appointment.
    <div className="spacer"></div>

    <div className="subtitle">Payment</div>
    I accept cash. Thank you!
    <div className="spacer"></div>
    You can contact me with any questions or concerns at: <a href="mailto:booking.kathylee@gmail.com">booking.kathylee@gmail.com</a>

    <div className="spacer-lg"></div>

    <span className='bold larger'>Once you have read all of the above information, click the button below to open my booking form: </span>
    <div className="spacer-lg"></div>
    <div className="buttonContainer">
      <button className="venueButton">
        <a href="https://venue.ink/@thekathylee/">Booking Form (redirects to Venue)</a>
      </button>
    </div>

  </div>
  );
}

export default Faq;
