import React from 'react';
import './App.css';
import Navbar from './components/navbar/navbar';

import { initializeApp } from 'firebase/app';

function App() {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBGgHRgvfXoQBW9Qa7pRjlYGHOFtw6gVZ8',
  authDomain: 'thekathylee-6ee03.firebaseapp.com',
  projectId: 'thekathylee-6ee03',
  storageBucket: 'thekathylee-6ee03.appspot.com',
  messagingSenderId: '69857927636',
  appId: '1:69857927636:web:bf2f9bc5e47003eb47f641',
  measurementId: 'G-4JPT75SV6W'
};
  initializeApp(firebaseConfig);

  return (
    <div className='App'>
      <Navbar/>
    </div>
  );
}

export default App;
