import React from 'react';
import {
    BrowserRouter as Router,
    Link,
    Routes,
    Route,
    NavLink
  } from 'react-router-dom';
import Flash from '../../pages/flash/flash';
import Aftercare from '../../pages/aftercare/aftercare';
import Home from '../../pages/home/home';
import './navbar.scss';
import Cart from '../../pages/ceramics/cart/cart';
import Faq from '../../pages/faq/faq';

function Navbar() {
    const [scrollTop, setScrollTop] = React.useState(0);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(1);
  const accentColor = "pink";
  const instagram = 'https://www.instagram.com/thekathylee/';

  const handleScroll = (event: Event) => {
    if (window.scrollY !== scrollTop) {
      setScrollTop(window.scrollY);
    }
  }

  window.addEventListener('scroll', handleScroll);
  document.addEventListener('click', () => {setMenuOpen(false);})

  return (
    <Router>
      <div className={`header ${menuOpen ? 'open' : ''} ${accentColor} ${scrollTop > 30 ? 'smaller' : ''}`}
      onClick={(event) => {event.stopPropagation()}}>
          <Link className="logoContainer" to="/" 
          onClick={() => {
            setActiveTab(1);
            setMenuOpen(false);
          }}>
            <img className='logo' src='/logo.jpeg'/>
          </Link>
          <div className="linkContainer">
             <NavLink className="pageLink" to="/faq" 
              onClick={(isActive) => {
                if(isActive) {
                  setActiveTab(1);
                  setMenuOpen(false);
                }}}>
              {({ isActive }) =>(
                <span className={`linkText ${activeTab === 1 ? 'active' : ''}`}>booking info</span>
              )}
              </NavLink>
              <NavLink className="pageLink" to="/aftercare" 
              onClick={(isActive) => {
                if(isActive) {
                  setActiveTab(2);
                  setMenuOpen(false);
                }}}>
              {({ isActive }) =>(
                <span className={`linkText ${activeTab === 2 ? 'active' : ''}`}>prep / aftercare</span>
              )}
              </NavLink>
              <div className="pageLink"
                onClick={(isActive) => {
                  if(isActive) {
                    setActiveTab(3);
                    setMenuOpen(false);
                    window.location.href = 'http://instagram.com/thekathylee.flash/';
                  }}}>
                  <span className={`linkText ${activeTab === 3 ? 'active' : ''}`}>flash (IG)</span>
              </div>

              <div className='instagramContainer'>
                <a className='instagram' href={instagram} target="_blank" rel="noreferrer"></a>
              </div>
            </div>
            <button className="menu" onClick={() => setMenuOpen(!menuOpen)}>
              <div className="topBar"></div>
              <div className="bottomBar"></div>
            </button>
      </div>

      <Routes>
        <Route path='/' element={< Faq />}></Route>
        <Route path='/faq' element={< Faq />}></Route>
        <Route path='/aftercare' element={< Aftercare />}></Route>
      </Routes>
    </Router>
  );
} 

export default Navbar;